<template>
    <div class="container1150">
        <header><strong>EV-LP压力式液位计</strong></header>
        <main>
            <p>
                EV-LP型压力式液位计是一款适用于水利水情行业监测地表水、地下水液位和水温的压力式液位计，采用高稳定性感压元器件进行静水压力与水深关系换算，传感器输出信号经过精密信号调理，由微处理器进行全数字化的线性校正和温度补偿后，得到稳定的、高精度的液位和水温值。产品采用RS485通信接口，采用标准的
                MODBUS - RTU
                通讯协议，可以同时检测液位、水温、压力、时钟、电池电量、存储数据等多种参数。产品内部集成大容量存储芯片及高容量锂电池，高精度实时时钟，具有自动定时存储功能，产品的微功耗设计大大延长了内部锂电池的使用寿命，适合长期的液位水温监测应用
            </p>
            <div class="img1"></div>
            <h2>产品特点</h2>
            <p>● · 高精度，最大可达0.05%F.S</p>
            <p>● · 全温区温度补偿，全量程数字校准</p>
            <p>● · 低功耗特性，休眠状态工作电源＜5μ A</p>
            <p>● · 宽电源供电，5~30VDC</p>
            <p>● · 产品同时带有电源反接保护及浪涌电压保护</p>
            <p>● · RS485标准 MODBUS - RTU 协议，可在 DCS 或 PLC 系统中使用</p>
            <p>● · 高稳定性液位测量传感元件，长期稳定性好</p>
            <p>● · 过载能力强</p>
            <p>● · 温度测量，分辨率0.05℃</p>
            <p>● · 全不锈钢（或钛合金）外壳密封结构，IP68防护</p>
            <div class="img2"></div>
            <div class="img3"></div>
            <!-- <div class="img4"></div> -->
            <!-- <div class="img5"></div> -->
        </main>
    </div>
</template>
<script>
export default {
    data() {
        return {};
    },
};
</script>
<style lang="scss" scoped>
.container1150 {
    width: 1150px;
    header {
        height: 70px;
        line-height: 70px;
        text-align: center;
        font-size: 26px;
    }
    main {
        p {
            // height: 20px;
            font-size: 16px;
            line-height: 30px;
        }
        h2 {
            height: 60px;
            line-height: 60px;
        }
        .img1 {
            width: 100%;
            // width: 450px;
            height: 600px;
            margin: 10px auto;
            background-image: url("/img/product/12-1.jpg");
            background-repeat: no-repeat;
            background-size: 100% 100%;
        }
        .img-list {
            display: flex;
            width: 100%;
            .img1 {
                // width: 100%;
                width: 550px;
                height: 450px;
                margin: 10px auto;
                background-image: url("/img/product/5-2.jpg");
                background-repeat: no-repeat;
                background-size: 100% 100%;
            }
            .img2 {
                // width: 100%;
                width: 550px;
                height: 450px;
                margin: 10px auto;
                background-image: url("/img/product/5-3.jpg");
                background-repeat: no-repeat;
                background-size: 100% 100%;
            }
        }
        .img2 {
            width: 1000px;
            height: 500px;
            margin: 10px auto;
            background-image: url("/img/product/12-2.jpg");
            background-repeat: no-repeat;
            background-position-y: -1%;
            background-size: 100% 101%;
        }
        .img3 {
            width: 1100px;
            height: 700px;
            margin: 10px auto;
            background-image: url("/img/product/12-3.jpg");
            background-repeat: no-repeat;
            background-size: 100% 100%;
        }
        // .img4 {
        //     width: 1100px;
        //     height: 350px;
        //     margin: 10px auto;
        //     background-image: url("/img/product/11-4.jpg");
        //     background-repeat: no-repeat;
        //     background-size: 100% 100%;
        // }
        // .img5 {
        //     width: 800px;
        //     height: 1050px;
        //     margin: 10px auto;
        //     background-image: url("/img/product/10-4.jpg");
        //     background-repeat: no-repeat;
        //     background-size: 100% 100%;
        // }
    }
}
</style>